import React, { SetStateAction, useEffect, useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { createFC } from "../../../../../helpers/createFC";

let timeoutId = 123;

type propsType = {
  primaryColor?: "default" | "blue";
  disabled?: boolean;
  timeout?: number;
  sendCodeHandler?(): void;
  retryCount: number;
  setRetryCount: React.Dispatch<React.SetStateAction<number>>;
  setTimeFromCodeRequested?: React.Dispatch<SetStateAction<number>>;
};

export const CodeTimer = createFC(({
  primaryColor = "default",
  disabled,
  timeout = 60,
  retryCount,
  setRetryCount,
  sendCodeHandler,
  setTimeFromCodeRequested,
}: propsType) => {
  const [timer, setTimer] = useState(timeout);

  function createTimer() {
    window.clearInterval(timeoutId);
    timeoutId = window.setInterval(updateTimer, 1000);
  }

  function updateTimer() {
    setTimer((timer) => {
      if (timer <= 0) {
        window.clearInterval(timeoutId);
        return 0;
      } else {
        return timer - 1;
      }
    });
  }

  useEffect(() => {
    if (setTimeFromCodeRequested && timer === 0) {
      setTimeFromCodeRequested(0);
    }
  }, [timer]);

  useEffect(() => {
    timeoutId = window.setInterval(updateTimer, 1000);
  }, []);

  return (
    <div className={styles.timer}>
      {timer >= -1 && (
        <>
          <div
            onClick={() => {
              if (timer == 0 && retryCount < 5) {
                setTimer(timeout);
                setRetryCount(retryCount + 1);
                createTimer();
              }
            }}
            className={cn(styles.timer_text, {
              [styles["resend_code--disabled"]]: retryCount == 5,
            })}
          >
            <div className={cn(styles.repeat_title, {
              [styles.disabled]: disabled,
            })}>
              {" "}
              {timer > 0 ? (
                <>
                  Запросить код еще раз через{" "}
                  <span>
                    {timer >= 60
                      ? "01:" + (Math.floor(timer) - 60 + "0")
                      : "00:" +
                      (
                        timer >= 10
                          ? Math.floor(timer)
                          : "0" + Math.floor(timer)
                      )
                    }
                  </span>
                </>
              ) : (
                <>
                  {retryCount === 5 ? (
                    <div className={styles.repeat_title}>Попытки исчерпаны, попробуйте позже</div>
                  ) : (
                    <div
                      className={cn(styles.resend_code, {
                        [styles.resend_code_blue]: primaryColor === "blue",
                        [styles.disabled]: disabled,
                      })}
                      onClick={sendCodeHandler}
                    >
                      Отправить код еще раз
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});
