import React, { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Range } from "react-range";
import cn from "classnames";
import styles from "./index.module.css";
import { stringHelpers } from "../../../helpers/string";
import { RangeType } from "./types";
import { Icons } from "../../../assets/icons";
import { roundDownToNearestThousand } from "../Conditions";

// * PROPS
// * marks - показывать или нет метки между step
// * step - сколько будет занимать шаг ползунка
// * min - минимальное значение
// * max - максимальное значение
// * rangeData - данные, которые будут отображаться снизу под range элементом
// * className - будет улетать в обёртку (сontainer крч)
// * name - нужен нам тут для сета значения из Range в форму + чтобы отслеживать value формы и реагировать правильно
// * setValues - это функция для сета значений самого Range компонента
// * values - значения самого Range компонента

export const RangeSlider: FC<RangeType> = ({
  amountForTextInformation,
  textInformation,
  amount,
  conditionForLockRangeAmount,
  conditionForLockRangeLoanTerms,
  marks,
  step = 1,
  min,
  max,
  rangeData,
  className,
  name,
  setValues,
  values,
  onChange,
  disabled,
  primaryColor,
  ...jsxAttrs
}) => {
  const { setValue, control } = useFormContext();

  // * сама линия у react-range не подходит, поэтому я её скрыл и сделал кастомные, тут высчитывается проценты width каждой линии
  const filledLine = ((values[0] - min) * 100) / (max - min);
  const unfilledLine = 100 - ((values[0] - min) * 100) / (max - min);

  const formValue = useWatch({
    control,
    name: name,
  });

  // * юзер в инпут может ввести значение любое, но Range компонента у нас рассчитана только на диапазон min-max
  //  * поэтому я проверяю значение в инпуте и если оно неподходящее, то не сечу в Range
  useEffect(() => {
    if (formValue && formValue !== values[0]) {
      formValue >= min && formValue <= max && setValues([formValue]);
    }
  }, [formValue]);

  return (
    <div
      className={cn(styles.container, {
        [`${className}`]: className,
        [styles.track_container_disabled]: disabled,
      })}
      {...jsxAttrs}
    >
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={(values) => {
          setValues(values);
          setValue(name, values[0]);
          onChange && onChange();
        }}
        // * это метки
        renderMark={
          marks && !conditionForLockRangeLoanTerms
            ? ({ props, index }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                  }}
                  className={cn(styles.mark, {
                    [styles.mark_filled]: min + index * step < values[0],
                  })}
                >
                  {/*{max / step !== index && index !== 0 && rangeData && (*/}
                  {/*  <div className={cn(styles.mark_text, styles.text)}>*/}
                  {/*    {rangeData[index]}*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              )
            : undefined
        }
        // * это линии по которым бегаем
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
            className={styles.track_container}
          >
            <div ref={props.ref} className={styles.track_line_invisible}>
              {children}
              <div
                style={{
                  width: `${filledLine}%`,
                }}
                className={cn(styles.track_line_filled, {
                  [styles.track_line_disabled]: disabled,
                  [styles.track_line_filled_blue]: primaryColor === "blue",
                })}
              />
              <div
                style={{
                  width: `${unfilledLine}%`,
                }}
                className={styles.track_line_unfilled}
              />
            </div>
          </div>
        )}
        // * Это кружочек, на который жмякаем
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
            }}
            className={styles.thumb}
          >
            <div
              className={cn(styles.circle, {
                [styles.circle_Dragged]: isDragged,
              })}
            />
            {(conditionForLockRangeLoanTerms ||
              conditionForLockRangeAmount) && <Icons.ui.LockIcon />}
            {marks && !conditionForLockRangeLoanTerms && (
              <>
                <div className={cn(styles.line_top, {
                  [styles.line_top_blue]: primaryColor === "blue"
                })} />
                <div className={cn(styles.line_bottom, {
                  [styles.line_bottom_blue]: primaryColor === "blue"
                })} />
              </>
            )}
          </div>
        )}
      />
      {!marks && <div className={styles.circle_end} />}
      {/*  * Это всё, что ниже range */}
      {!conditionForLockRangeAmount && !conditionForLockRangeLoanTerms && (
        <>
          <div
            className={cn(styles.text_left, styles.text, {
              [styles.text_left_mark]: marks,
              [styles.text_left_mark_error]:
                amount && amount < roundDownToNearestThousand(min),
            })}
          >
            {rangeData
              ? rangeData[0]
              : `${stringHelpers.transformMoneyValue(min)} ₽`}
          </div>

          {/*{textInformation &&*/}
          {/*  amountForTextInformation &&*/}
          {/*  amountForTextInformation < 80000 && (*/}
          {/*    <div className={styles.information}>*/}
          {/*      Больше 12 месяцев - от 80 000₽*/}
          {/*    </div>*/}
          {/*  )}*/}

          <div
            className={cn(styles.text_right, styles.text, {
              [styles.text_right_mark]: marks,
              [styles.text_right_mark_error]: amount && amount > max,
            })}
          >
            {rangeData
              ? rangeData[1]
              : `${stringHelpers.transformMoneyValue(
                  max === 80001 ? 80000 : max
                )} ₽`}
          </div>
        </>
      )}
    </div>
  );
};
